<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
    <work-view-date-of-work :result="result" :is-show-loading="isShowLoading"/>
    <work-view-contractor :result="result" :is-show-loading="isShowLoading"/>
    <work-view-jsa :work-id="id" :card-collapsed="true"/>
    <work-view-safety-check :work-id="id" :card-collapsed="true"/>
    <work-view-checkin-history :work-id="id" :card-collapsed="true"/>
    <work-view-activity :work-id="id" :card-collapsed="true"/>

    <b-row>
      <b-col class="text-center">
        <b-button variant="secondary" class="my-1" :to="{name: 'pageWork'}">
          <feather-icon icon="ChevronLeftIcon"/>
          {{ $t('general.btnGoHome') }}
        </b-button>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewContractor from "@/components/work/WorkViewContractor"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import WorkViewSafetyCheck from "@/components/work/WorkViewSafetyCheck.vue"
import WorkViewActivity from "@/components/work/WorkViewActivity"
import WorkViewCheckinHistory from "@/components/work/WorkViewCheckinHistory"
import {WorkService} from "@/services"

const workService = new WorkService()

export default {
  name: 'WorkFormView',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewContractor,
    WorkViewJsa,
    WorkViewSafetyCheck,
    WorkViewActivity,
    WorkViewCheckinHistory,
  },
  async created() {
    this.id = this.$route.params.id
    await this.getData()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    result: {},
  }),
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result
        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
  }
}
</script>
